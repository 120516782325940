import { Link, graphql } from "gatsby";
import parse from "html-react-parser";
import React, { useEffect, useRef } from "react";
import Image from "gatsby-image";
import Layout from "../components/layout";
import Seo from "gatsby-plugin-wpgraphql-seo";
import CategoryMenu from "../components/CategoryMenu";
import LinkArrowShort from "../assets/svgs/link-arrow-short.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import formatDate from "../../utils/formatDate";

const BlogIndex = ({ posts, location, categories }) => {
  const sideMenuRef = useRef(null);

  // GSAP ScrollTrigger for the side menu
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let mm = gsap.matchMedia();

    mm.add("(min-width: 1280px)", () => {
      // This code only runs when the viewport is at least 1280px wide
      return ScrollTrigger.create({
        trigger: sideMenuRef.current,
        start: "top top",
        endTrigger: ".content-container",
        end: "bottom bottom",
        pin: true,
        pinSpacing: false,
        markers: false,
      });
    });

    // Cleanup function
    return () => mm.revert();
  }, []);

  return (
    <div className={"content-container"}>
      <div className={"grid gap-6 grid-cols-1 desktop:grid-cols-[400px_1fr]"}>
        {/*Side menu*/}
        <div
          ref={sideMenuRef}
          className={
            "relative grid grid-cols-1 justify-between py-14 pr-[10%] tablet:pr-0 tablet:grid-cols-2 desktop:flex desktop:flex-col desktop:h-screen desktop:h-[100svh] desktop:sticky desktop:top-0"
          }
        >
          <h1 className={"text-secondary text-7xl font-bold mb-8 tablet:mb-0"}>
            News
          </h1>
          <CategoryMenu categories={categories} />
        </div>

        {/*Posts*/}
        <div
          className={
            "self-start grid grid-cols-1 tablet:grid-cols-2 gap-x-4 gap-y-8 pb-14 tablet:py-14"
          }
        >
          {posts?.map((post, index) => {
            const { title, uri, newsInfo, categories } = post;

            const featuredImage = {
              fluid:
                post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
              alt: post.featuredImage?.node?.alt || ``,
            };

            return (
              <Post
                key={index}
                index={index}
                featuredImage={featuredImage}
                newsInfo={newsInfo}
                categories={categories}
                title={title}
                uri={uri}
                date={post?.date}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const PageCategories = ({ categories }) => {
  // Commas between categories
  return (
    <p className={"block text-secondary font-medium text-sm"}>
      {categories?.nodes.map(
        (category, index) =>
          category.name + (index < categories.nodes.length - 1 ? ", " : "")
      )}
    </p>
  );
};

const Post = ({
  featuredImage,
  newsInfo,
  categories,
  title,
  index,
  uri,
  date,
}) => {
  const iconRef = useRef(null);
  const imageRef = useRef(null);

  // Get date
  const dateRegex = /Date (\d{2})\.(\d{2})\.(\d{4})/;
  const formattedDate = date?.replace(dateRegex, "$1.$2.$3".slice(-2));

  function animateIn() {
    // Move icon
    gsap.to(iconRef.current, {
      duration: 0.3,
      x: 0,
    });

    // Zoom in image
    gsap.to(imageRef.current, {
      duration: 0.3,
      scale: 1.05,
    });
  }

  function animateOut() {
    // Move icon
    gsap.to(iconRef.current, {
      duration: 0.3,
      x: -20,
    });

    // Zoom out image
    gsap.to(imageRef.current, {
      duration: 0.3,
      scale: 1,
    });
  }

  return (
    <Link
      to={uri}
      className="grid grid-cols-1"
      onMouseEnter={animateIn}
      onMouseLeave={animateOut}
    >
      {featuredImage?.fluid && (
        <div className="w-full aspect-[3/2] mb-2 overflow-hidden">
          <div ref={imageRef} className={"w-hull h-full"}>
            <Image
              className="w-full h-full object-cover"
              fluid={featuredImage.fluid}
              alt={featuredImage.alt}
            />
          </div>
        </div>
      )}

      <div className={"border-b-2 border-secondary pb-5"}>
        {date && (
          <p className={"text-secondary text-4xl mb-"}>{formatDate(date)}</p>
        )}
        <h2 className={"text-lg text-secondary font-bold truncate"}>
          {parse(title)}
        </h2>
        <div className={"mt-3 flex justify-between items-center"}>
          <PageCategories categories={categories} />
          <div
            className={
              "hidden desktop:block w-[150px] pr-[25px] overflow-hidden flex-shrink-0"
            }
          >
            <div ref={iconRef} className={"w-[150px] -translate-x-[25px]"}>
              <LinkArrowShort className={"w-full"} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const BlogPage = ({
  data,
  location,
  pageContext: { nextPagePath, previousPagePath, categories },
}) => {
  const posts = data?.allWpPost?.nodes;

  return (
    <Layout>
      <Seo
        title={"News"}
        postSchema={JSON.parse(data.wp.seo.contentTypes.post.schema.raw)}
      />
      <BlogIndex posts={posts} location={location} categories={categories} />
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query WordPressCategoryPostArchive(
    $id: String
    $offset: Int!
    $postsPerPage: Int!
  ) {
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        content
        date(formatString: "MMMM DD, YYYY")
        title
        blocks {
          blockName: name
          innerHTML: saveContent
          dynamicContent
          innerBlocks {
            blockName: name
            innerHTML: saveContent
            innerBlocks {
              blockName: name
              innerHTML: saveContent
            }
          }
        }
        categories {
          nodes {
            name
          }
        }
        newsInfo {
          additionalInfo
          subtitle
          relatedArticle {
            target
            title
            url
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
