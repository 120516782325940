import React, {useRef} from "react";
import { Link } from "gatsby";

const CategoryMenu = ({ categories }) => {
  const filteredCategories = categories.filter(category => category.name !== "Uncategorized");

  // Add a link to the home page
  filteredCategories.unshift({
    id: "all-news",
    name: "All News",
    uri: "/news",
  });

  return (
    <nav>
      <ul>
        {filteredCategories.map((category) => {
          // Get the current uri
          const currentUri = typeof window !== `undefined` ? window.location.pathname : "";

          // Check if item is active
          const isActive = currentUri === category.uri;

          return (
            <CategoryItem key={category.id} category={category} isActive={isActive} />
          )
        })}
      </ul>
    </nav>
  );
};

const CategoryItem = ({ category, isActive }) => {
  return (
    <li
      key={category.id}
      className={`border-y-2 border-secondary border-t-light cursor-pointer transition-colors duration-300 hover:border-secondary hover:bg-secondary hover:*:!text-light hover:*:translate-x-[10px] ${isActive && "bg-secondary !border-secondary"}`}
    >
      <Link to={category.uri} className={`block font-medium px-2 text-secondary py-0.5 transition-transform duration-300 ${isActive && "!text-light !translate-x-0"}`}>{category.name}</Link>
    </li>
  );
};

export default CategoryMenu;
